<!--
Author: Training
Date: 2024-04-17
Description:
封装了el-upload
默认使用本项目的上传地址
在点击删除时默认调用删除接口(不需要再自己手动调用了)
新增了pdf的文件样式回显

新增参数: tip (原先tip插槽的功能 , 但也还支持插槽的方式使用tip)
         size: 文件大小限制 单位MB
         triggerType: 触发器类型 , 默认为button (就是上传组件的样式)
         listType: 这是原来的参数, 新增了一个选项"pdf"
         button: 按钮的参数配置(当triggerType为button时, 此参数才有效)
         deleteServerFile: 是否删除服务器文件(在点击x的时候 ， 一并删除服务器的文件 ， 如果status不是success， 则不会删除)
插槽: default 默认插槽 , trigger : 自定义触发器   tip: 提示信息
取消了参数: action (默认使用项目中的上传地址)
-->
<template>
  <div class="t-upload">
    <el-upload
        ref="upload"
        :auto-upload="autoUpload"
        :action="action"
        name="upload"
        :data="data"
        :accept="cAccept"
        :before-upload="onBeforeUpload"
        :on-success="onSuccess"
        :on-change="change"
        :before-remove="onBeforeRemove"
        :on-preview="onPreview"
        :on-progress="onProgress"
        :on-remove="remove"
        :on-error="error"
        :limit="limit"
        :http-request="httpRequest"
        :disabled="disabled"
        :show-file-list="cShowFileList"
        :multiple="multiple"
        :drag="drag"
        :list-type="cListType"
        :on-exceed="onExceed"
        :file-list="fileList"
    >
      <template slot="default">
        <slot></slot>
      </template>
      <template slot="trigger">
        <div class="t-upload-list" v-if="cListType == null" @click.stop>
          <div class="t-upload-list-item" v-for="(item,index) in list" :key="item.uid"  v-loading="item.loading">
            <img :src="cFileIcon" alt="文件图标">
            <div class="t-upload-list-item-right">
              <el-tooltip class="item" effect="dark" :content="item.name" >
                <div class="t-upload-list-item-title">
                  {{item.name}}
                </div>
              </el-tooltip>
              <div class="t-upload-list-item-size" v-show="item.status === 'success'">
                {{cSize(item.size)}}
              </div>
              <div class="t-upload-list-item-progress" v-show="item.status === 'uploading'">
                <el-progress :stroke-width="2" :percentage="parseInt(item.percentage || '0')"></el-progress>
              </div>
            </div>
            <i class="t-upload-list-item-close el-icon-close" @click="deleteThis(item,index)"></i>
          </div>
        </div>
        <slot name="trigger"></slot>
        <template v-if="!$slots.trigger">
          <div class="t-upload-list-item-trigger" style="display: flex;" v-if="triggerType === 'button'">
            <el-button type="primary">{{ button.text }}</el-button>
            <div class="t-upload-space" style="width:100%;height:45rem;cursor: default;" @click.stop></div>
          </div>

        </template>
      </template>

      <div slot="tip">
        <slot name="tip"></slot>
        <span v-if="tip" class="el-upload__tip">{{tip}}</span>
      </div>

    </el-upload>
  </div>
</template>

<script>
import pdf from "@/assets/img/pdf-icon.png"
export default {
  name: "Upload",
  props:{
    // 是否删除服务端的文件
    deleteServerFile:{
      type:Boolean,
      default:true
    },
    // 允许上传的最大文件大小，单位为MB , 0为不限制
    size:{
      type:Number,
      default:0
    },
    // 触发器的类型 button:按钮
    triggerType:{
      type:String,
      default:'button',
      validator(value){
        return ['button'].includes(value);
      }
    },
    // 按钮配置
    button:{
      type:Object,
      default(){
        return {
          text:"点击上传",
        }
      }
    },
    // 提示信息
    tip:String,
    /**以下是原生属性*/
    accept:String,
    autoUpload:{
      type:Boolean,
      default:true
    },
    data:{
      type:Object,
      default(){
        return {
          type:1,
          use_outer_url:1,
        }
      }
    },
    fileList:Array,
    limit:Number,
    httpRequest:Function,
    disabled:Boolean,
    multiple:Boolean,
    drag:Boolean,
    listType:{
      type:String,
      default:'text',
      validator(value){
        return ['text','picture','picture-card',"pdf"].includes(value);
      }
    },
    showFileList:{
      type:Boolean,
      default:true
    },
    beforeUpload:Function,
    beforeRemove:Function,
    onSuccess:{
      type:Function,
      default(){
        return function(response, file, fileList){};
      }
    },
    onPreview:{
      type:Function,
      default(){
        return function(file){};
      }
    },
    onRemove:Function,
    onError:{
      type:Function,
      default(){
        return function(err, file, fileList){};
      }
    },
    onProgress:{
      type:Function,
      default(){
        return function(event, file, fileList){};
      }
    },
    onChange:{
      type:Function,
      default(){
        return function(file, fileList){};
      }
    },
    onExceed:{
      type:Function,
      default(){
        return function(files, fileList){};
      }
    },
  },
  computed:{
    cListType(){
      if (this.listType !== "pdf")
        return this.listType;
      else return null;
    },
    cShowFileList(){
      if (this.cListType == null)return false;
      return this.showFileList;
    },
    cFileIcon(){
      // 计算当前应该使用什么图标
      switch (this.listType) {
        case "pdf":
          return pdf;
      }
    },
    cAccept(){
      if (this.accept)return this.accept;
      switch (this.listType){
        case "pdf":
          return "application/pdf";
        default:
          return 'image/png,image/jpeg';
      }
    }
  },
  watch:{
    fileList(val){
      this.list = val;
    },
  },
  data(){
    return {
      action:process.env.VUE_APP_URL_TWO+"/api/site/upload",
      list:[],
    }
  },
  methods:{
    // 检测文件类型
    checkFileType(file){
      if (file.type.indexOf(this.cAccept)>-1)return  null;
      let type = this.cAccept.split(",").map(item=>{
        return item.split("/")[1];
      }).join("或");
      return {
        type,
        message:`文件类型错误,请上传${type}格式的文件`
      };
    },
    // 计算文件大小
    cSize(num){
      if (num<1024){
        return num+"B"
      }else if (num<1024*1024){
        return (num/1024).toFixed(2)+"KB"
      }else if (num<1024*1024*1024){
        return (num/(1024*1024)).toFixed(2)+"MB"
      }else{
        return (num/(1024*1024*1024)).toFixed(2)+"GB"
      }
    },
    error(err,file,fileList){
      this.$message.error({message:`"${file.name}"上传失败,请重试`,duration:3000,showClose:true});
      this.onError(err,file,fileList);
    },
    change(file,fileList){
      this.list = fileList;
      this.onChange(file,fileList);
    },
    remove(file,fileList){
      this.list = fileList;
      this.change(file,fileList);
      this.onRemove&&this.onRemove(file,fileList);
    },
    onBeforeRemove(file, fileList){
      let doRemove = ()=>{
        if (!this.deleteServerFile || file.status !== "success")return null;
        if (!file.response.data.file_addr)return Promise.resolve();
        file.loading =true;
        return this.$deleteFile(file.response.data.file_addr).then(res=>{
          return Promise.resolve()
        }).catch(res=>{
          return Promise.reject(res);
        }).finally(()=>file.loading=false)
      }
      if (this.beforeRemove){
        let res = this.beforeRemove(file, fileList);
        if (res&&res.then){
          return res.then(()=>{
            return doRemove();
          })
        }else if(res === false)return false;
      }
      return doRemove();
    },
    onBeforeUpload(file){
      let size = file.size;
      let flag = this.checkFileType(file);

      if(flag){
        this.$message.warning({message:flag.message,duration:2000,showClose:true});
        return false;
      }
      if (this.size){
        if (size>this.size*1024*1024){
          this.$message.warning({message:'文件大小不能超过'+this.size+'MB',duration:2000,showClose:true});
          return false;
        }
      }
      if (this.beforeUpload)return this.beforeUpload(file);
    },
    deleteThis(item){
      this.$refs.upload.handleRemove(item)
    },
    clearFiles(){
      this.$refs.upload.clearFiles();
      this.list=[];
    },
    abort(file){
      this.$refs.upload.abort(file);
    },
    submit(){
      this.$refs.upload.submit();
    },
    /**
     * 重传
     * @param {File} file 要上传的文件
     * @param raw 原始文件
     */
    reUpload(file,raw){
      this.$refs.upload.handleRemove(raw);
      this.$refs.upload.handleStart(file);
      this.$refs.upload.submit();
    }
  }
}
</script>

<style lang="scss" scoped>
.t-upload{
  ::v-deep .el-upload{
    display: block;
    text-align: left;
  }
}
.t-upload-list{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &-item{
    position: relative;
    margin-right: 10rem;
    margin-bottom: 10rem;
    width: 212rem;
    height: 68rem;
    padding: 8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #F6F9FF;
    &-close{
      position: absolute;
      right: 8rem;
      top: 8rem;
      transition : all linear .2s;
    }
    &-close:hover{
      transform:rotateZ(90deg);
    }
    >img{
      width: 48rem;
      height: 48rem;
    }
    &-right{
      margin-left: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      >div{
        line-height: 22rem;
      }
    }
    &-title{
      // 超出部分显示省略号
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex:1;
      width: 120rem;
      margin-bottom: 6rem;
      color: #3D3D3D;
      font-size: 16rem;
    }
    &-size{
      color: #888888;
      font-size: 14rem;
    }
    &-progress{
      width:138rem;
    }
  }
}

</style>
